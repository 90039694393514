import React from 'react';
import { withApollo } from '@/lib/withApollo';
import Default from '../layouts/default';
import LoginForm from '../components/template/login-form';
import VLibras from '@djpfs/react-vlibras';

export const Login = () => (
  <Default>
    <VLibras />
    <LoginForm />
  </Default>
);

export default withApollo({ ssr: true })(Login);

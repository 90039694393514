import React from 'react';

import { Container, AppBar, Box, Toolbar } from '@material-ui/core';

import { Logo } from '@eureca/eureca-ui';
import VLibras from '@djpfs/react-vlibras';

// eslint-disable-next-line react/prop-types
export default function Default({ children }) {
  return (
    <Box
      display="flex"
      flexDirection="column"
      minHeight="100vh"
      justifyContent="center"
    >
      <AppBar position="fixed" color="transparent" elevation={0}>
        <Toolbar>
          <Box width={82}>
            <Logo />
          </Box>
        </Toolbar>
        <VLibras />
      </AppBar>
      <Container>{children}</Container>
    </Box>
  );
}

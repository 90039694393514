import { useApolloClient, useMutation } from '@apollo/react-hooks';
import { useRouter } from 'next/router';
import Cookies from 'js-cookie';
import jwtDecode from 'jwt-decode';
import { useSnackbar } from 'notistack';
import { LOGIN_CONSULTANT } from '@/api/mutation/consultant/index';

const useLogin = () => {
  const client = useApolloClient();
  const history = useRouter();
  const { enqueueSnackbar } = useSnackbar();

  const [login, setLogin] = useMutation(LOGIN_CONSULTANT, {
    onCompleted(data) {
      const { token } = data.tokenAuth;
      Cookies.set('token', token);
      const profile = jwtDecode(token);
      client.cache.writeData({
        data: {
          isLoggedIn: true,
          profile: profile.identity,
          token,
        },
      });
      history.push('/oportunidades');
    },
    onError({ graphQLErrors }) {
      if (graphQLErrors !== undefined) {
        graphQLErrors.forEach((item) => {
          enqueueSnackbar(item.message, { variant: 'error' });
        });
      }
    },
  });

  return [login, setLogin];
};

export default useLogin;

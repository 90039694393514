import React, { useState } from 'react';
import { Link as RouterLink } from 'next/link';
import { Formik } from 'formik';
import * as Yup from 'yup';
import {
  Grid,
  Typography,
  Box,
  Link,
  Hidden,
  TextField,
} from '@material-ui/core';
import { Button, Checkbox, Password } from '@eureca/eureca-ui';

import { yupValidators } from '../../helpers/validations';
import useLogin from '../../hooks/consultant/useLogin';

const validation = Yup.object().shape({
  email: yupValidators.email,
  password: Yup.string().required('É preciso colocar a senha!'),
});

const initialValues = {
  email: '',
  password: '',
};

function Login() {
  const [rememberMe, setRememberMe] = useState(false);
  const [login] = useLogin();

  function handleSubmit(variables) {
    login({ variables });
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validation}
      onSubmit={handleSubmit}
    >
      {({ isValid, errors, touched, ...formikProps }) => (
        <form onSubmit={formikProps.handleSubmit}>
          <Grid
            container
            spacing={3}
            alignItems="center"
            justifyContent="center"
          >
            <Hidden only="xs">
              <Grid item sm={8} md={6}>
                <img
                  src="/intro-somos-humanos.svg"
                  alt="Somos todos humanos - Eureca"
                />
              </Grid>
            </Hidden>
            <Hidden smDown>
              <Grid item md={1} />
            </Hidden>
            <Grid item container xs={12} sm={8} md={4} spacing={0}>
              <Grid item xs={12}>
                <Box mb={3.75} textAlign={{ xs: 'center', md: 'left' }}>
                  <Typography variant="h3">Somos jovemcêntricos!</Typography>
                </Box>
                <Box mb={4}>
                  <TextField
                    variant="outlined"
                    label="E-mail"
                    margin="normal"
                    fullWidth
                    name="email"
                    onChange={formikProps.handleChange}
                    onBlur={formikProps.handleBlur}
                    value={formikProps.values.email}
                    error={!!errors.email}
                  />
                  {errors.email && touched.email ? (
                    <Typography variant="body2" color="error">
                      {errors.email}
                    </Typography>
                  ) : null}
                  <Password
                    variant="outlined"
                    label="Senha"
                    margin="normal"
                    fullWidth
                    name="password"
                    onChange={formikProps.handleChange}
                    onBlur={formikProps.handleBlur}
                    value={formikProps.values.password}
                    error={!!errors.password}
                  />
                  {errors.password && touched.password ? (
                    <Typography variant="body2" color="error">
                      {errors.password}
                    </Typography>
                  ) : null}
                </Box>
                <Box mb={4}>
                  <Grid item container xs={12} spacing={0} alignItems="center">
                    <Grid item xs={6}>
                      <Checkbox
                        name="rememberMe"
                        label="Lembrar acesso"
                        value={rememberMe}
                        checked={rememberMe}
                        onChange={setRememberMe}
                      />
                    </Grid>
                    <Grid item xs={6} align="right">
                      <Link component={RouterLink} href="/redefinir-senha">
                        Esqueci minha senha
                      </Link>
                    </Grid>
                  </Grid>
                </Box>
                <Box mb={3}>
                  <Grid item xs={12}>
                    <Button
                      variant="contained"
                      fullWidth
                      type="submit"
                      disabled={!isValid}
                      onClick={formikProps.handleSubmit}
                    >
                      Entrar
                    </Button>
                  </Grid>
                </Box>
                <Box textAlign="center">
                  <Grid item xs={12}>
                    <Typography>
                      Não tem acesso?{' '}
                      <Link component={RouterLink} href="/registrar">
                        Solicitar
                      </Link>
                    </Typography>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </form>
      )}
    </Formik>
  );
}

export default Login;
